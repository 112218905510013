import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts/Layout'
import LayoutArchive from '../layouts/LayoutArchive'


//Component
const InspoCatTemplate = ( props ) => {

  const data = props.data
  const current_page = data.wordpressWpInspoCategory
  const yoast_meta = current_page.yoast_meta
  
  const current_meta = {
    meta_title:     (yoast_meta) ? yoast_meta.yoast_wpseo_title : null,
    meta_desc:      (yoast_meta) ? yoast_meta.yoast_wpseo_metadesc : null,
  }

  const category_items = data.allWordpressWpInspoCategory.edges
  const archive_items = data.allWordpressWpInspo.edges

  return (
    <Layout 
      current_meta = {current_meta} 
    >

      <LayoutArchive 
        category_items = {category_items}
        current_category = {current_page.slug}
        archive_items = {archive_items}
        title_only_item = {true}
      />

    </Layout>
  )
}


//Export
export default InspoCatTemplate


//Query
export const currentInspoCatQuery = graphql`
query currentInspoCatQuery($id: String!){
  wordpressWpInspoCategory(id: { eq: $id }) {
    name
    slug
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
  }
  allWordpressWpInspoCategory (sort: {fields: description, order: ASC }) {
    edges {
      node {
        name
        slug
        link
      }
    }
  }
  allWordpressWpInspo (sort: {fields: menu_order, order: ASC}) {
    edges {
      node {
        id
        title
        link
        acf {
          heading
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fixed(width: 600, height: 400, cropFocus: CENTER) {
                  ...Fixed
                }
              }
            }
          }
        }
        cpt_categories {
          name
          slug
        }
      }
    }
  }
}
`